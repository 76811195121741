import React, { useState, useEffect } from "react";
import {useParams, useHistory} from "react-router-dom";
import { getNativeByChain } from "helpers/networks";
import { getCollectionsByChain } from "helpers/collections";
import {
  useMoralis,
  useMoralisQuery,
  useNewMoralisObject,
} from "react-moralis";
import { Card, Image, Tooltip, Modal, Badge, Alert, Spin, Button, Select } from "antd";
import { useNFTTokenIds } from "hooks/useNFTTokenIds";
import {
  FileSearchOutlined,
  RightCircleOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";
import { useMoralisDapp } from "providers/MoralisDappProvider/MoralisDappProvider";
import { getExplorer } from "helpers/networks";
import { useWeb3ExecuteFunction } from "react-moralis";

import Particles from "react-tsparticles";


import item_lg from '../img/item-lg.jpg';
import author_1 from '../img/authors/author-1.png';
import author_2 from '../img/authors/author-2.png';
import author_3 from '../img/authors/author-3.png';
import author_4 from '../img/authors/author-4.png';
import author_5 from '../img/authors/author-5.png';

const particlesInit = (main) => {
  console.log(main);

  // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
};

const particlesLoaded = (container) => {
  console.log(container);
};

function CollectionAdd({nft, viewType}) {
  const {tokenAddr, tokenId} = useParams();
  const history = useHistory();
  console.log('params', tokenAddr, tokenId);
  const [loading, setLoading] = useState(false);
  const { chainId, marketAddress, contractABI, walletAddress, erc20ContractABI, erc20Address } =
    useMoralisDapp();
  const contractABIJson = JSON.parse(contractABI);
  const contractProcessor = useWeb3ExecuteFunction();
  const queryMarketItems = useMoralisQuery("EvNewOffer");

  const [collectionAddrs, setCollectionAddrs] = useState('');
  const [collectionName, setCollectionName] = useState('');
  const [collectionImage, setCollectionImage] = useState('');

  const fetchMarketItems = JSON.parse(
    JSON.stringify(queryMarketItems.data, [
      "objectId",
      "createdAt",
      "user",
      "nft",
      "tokenId",
      "price",
      "side",
      "uid",
      "confirmed",
    ])
  );

  const addCollection = async () => {
    setLoading(true);
    const ops = {
      contractAddress: marketAddress,
      functionName: 'addCollection',
      abi: contractABIJson,
      params: {
        _addrs: collectionAddrs,
        _name: collectionName,
        _image: collectionImage
      },
    };
  
    await contractProcessor.fetch({
      params: ops,
      onSuccess: () => {
        console.log("success");
        setLoading(false);
        succCollectionAdd();
      },
      onError: (error) => {
        setLoading(false);
        failCollectionAdd();
      },
    });
  }

  function succCollectionAdd() {
    let secondsToGo = 5;
    const modal = Modal.success({
      
      title: "Success!",
      content: `The collection was added successfully`,
    });
    setTimeout(() => {
      modal.destroy();
    }, secondsToGo * 1000);
  }

  function failCollectionAdd() {
    let secondsToGo = 5;
    const modal = Modal.error({
      title: "Error!",
      content: `There was a problem when adding the collection`,
    });
    setTimeout(() => {
      modal.destroy();
    }, secondsToGo * 1000);
  }

  return (
    <div>
      <section className="py-5 item-bg position-relative">
        <Particles id="particles-js" url="http://foo.bar/particles.json" init={particlesInit} loaded={particlesLoaded} />
        
          <div className="container py-5 mt-5 z-index-20">
            <h1 className="text-center text-white">Add Collection</h1>
            
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb justify-content-center">
                <li className="breadcrumb-item">
                  <a className="text-decoration-none d-flex align-items-center" href="#"> <i className="las la-home la-sm me-1"></i>Home</a>
                </li>
                  <li className="breadcrumb-item active" aria-current="page">Add Collection</li>
              </ol>
            </nav>
        </div>
      </section>



      
      <section className="py-5">
        <div className="container py-5">
          <div className="row mb-4 gy-4">

             
            <div className="col-lg-12">
              
              <p className="text-muted mb-4 col-12">
                <div className="row">
                  
                  <div className="col-md-12">
                    <label>Collection Address</label>
                    <input type="text" className="form-control" value={collectionAddrs} placeholder="Collection Address" onChange={(e) => setCollectionAddrs(e.target.value)} />
                  </div>
                  <div className="col-md-12">
                    <label>Collection Name</label>
                    <input type="text" className="form-control" value={collectionName} placeholder="Collection Name" onChange={(e) => setCollectionName(e.target.value)} />
                  </div>
                  <div className="col-md-12">
                    <label>Collection Image</label>
                    <input type="text" className="form-control" value={collectionImage} placeholder="Collection Image URL" onChange={(e) => setCollectionImage(e.target.value)} />
                    {/* <input type="file" className=" file_design"  /> */}
                  </div>
                </div>
              </p>
              <p className="text-muted mb-4 col-12">
                <div className="row">
                  
                  <div className="col-md-12">
                    {
                      (!loading) ? (<button className="btn btn-primary btn-sm w-100" onClick={() => addCollection()}>Add</button>):
                      (<button className="btn btn-primary btn-sm w-100"><Spin/></button>)
                    }
                  </div>
                </div>
              </p>

               
             

               
              

            </div>
          </div>

           
        </div>
        </section>
        </div>
    )
}


export default CollectionAdd;