import React from "react";
import ReactDOM from "react-dom";

import { Web3ReactProvider } from "@web3-react/core";
import Web3 from "web3";

import App from "./App";
import "./index.css";

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
  console.info = () => {};
}

// const web3Provider = new Web3.providers.HttpProvider(
//   "https://api.avax-test.network/ext/bc/C/rpc"
// );
// const web3 = new Web3(web3Provider);
// return web3;
// return new Web3(provider);

function getLibrary(provider) {
  return new Web3(provider);
}

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <App />
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
