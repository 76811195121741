import React, { useState, useEffect } from "react";
import { Redirect } from "react-router";
import { useWeb3React } from "@web3-react/core";
import { Link } from "react-router-dom";
import { ethers } from "ethers";
import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import image1 from "../img/authors/author-1.png";
import item1 from "../img/items/item-1.jpg";
import item2 from "../img/items/item-2.jpg";
import item3 from "../img/items/item-3.jpg";
import item9 from "../img/items/item-9.jpg";
import item5 from "../img/items/item-5.jpg";
import item6 from "../img/items/item-6.jpg";
import item7 from "../img/items/item-7.jpg";
import item8 from "../img/items/item-8.jpg";

import MarketplaceContractJSON from "../contracts/Marketplace.json";
import SafeMarsNFTContractJSON from "../contracts/SafeMarsNFT.json";
import { ContactsOutlined } from "@ant-design/icons";

function MarketPlace({ web3 }) {
  const { account, library, active } = useWeb3React();
  const [store, setStore] = useState([]);
  const [listings, setListings] = useState([]);
  const [displayListings, setDisplayListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [itemOffset, setItemOffset] = useState(0);
  const [index, setIndex] = useState(0);
  const [libraryLoading, setLibraryLoading] = useState(true);

  useEffect(async () => {
    setListings([]);
    await getListedNfts();
  }, []);

  useEffect(async () => {
    if (active) {
      listings.forEach((data) => {
        data.isOwner = data.owner === account;
      });
    }
  }, [listings]);

  const getListedNfts = async () => {
    const marketplaceContract = new web3.eth.Contract(
      MarketplaceContractJSON.abi,
      process.env.REACT_APP_MARKET_PLACE
    );

    const safeMarsNFTContract = new web3.eth.Contract(
      SafeMarsNFTContractJSON.abi,
      process.env.REACT_APP_SAFEMARS_NFT
    );

    try {
      const listedNfts = await marketplaceContract.methods
        .getListedNfts()
        .call();

      if (listedNfts.length > 0) {
        for (const list of listedNfts) {
          if (list.seller == 0) continue;
          const tokenUri = await safeMarsNFTContract.methods
            .tokenURI(parseInt(list.tokenId))
            .call();

          const listing = await fetch(tokenUri);
          const data = await listing.json();

          if (data) {
            data.contractAddress = list.contractAddress;
            data.tokenId = +list.tokenId;
            data.price = +list.price / 1e18;
            data.startPrice = data.price;
            data.isOwner = list.seller === account;
            data.owner = list.seller;
            data.timestamp = moment(list.timestamp * 1000).fromNow() ?? "";
            data.created = data.timestamp;

            if (data.animation_type) {
              data.media_type = data.animation_type.split("/")[0];
            }

            setListings((oldArray) => {
              const newArray = [...oldArray];
              newArray.push(data);
              return newArray;
            });

            setDisplayListings((oldArray) => {
              const newArray = [...oldArray];
              newArray.push(data);
              return newArray;
            });
          }

          //console.log(data);
        }
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  const cancelTrade = async (data) => {
    const marketplaceContract = new library.eth.Contract(
      MarketplaceContractJSON.abi,
      process.env.REACT_APP_MARKET_PLACE
    );

    try {
      await toast.promise(
        marketplaceContract.methods
          .cancelTrade(data.contractAddress, data.tokenId)
          .send({ from: account }),
        {
          pending: "Cancelling...",
          success: "Success",
          error: "Failed to cancel trade",
        }
      );

      setDisplayListings((oldArray) => {
        const newArray = oldArray.filter(
          (item) => item.tokenId !== data.tokenId
        );
        return newArray;
      });
    } catch (err) {
      console.log(err);
    }
  };

  const search = async (value) => {
    const data = listings.filter((list) =>
      list.name.toLowerCase().includes(value.toLowerCase())
    );

    setDisplayListings(data);
  };

  const filterRecords = (index) => {
    setIndex(index);
    if (index === 0) {
      const data = listings;

      setDisplayListings(data);
    }

    if (index === 1) {
      const data = listings.filter((listing) => listing.owner === account);
      setDisplayListings(data);
    }
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % displayListings.length;
    setItemOffset(newOffset);
  };

  const showItem = (data, type) => {
    data.isOwner = data.owner === account;
    localStorage.setItem("nftData", JSON.stringify(data));
    localStorage.setItem("type", type);
    window.location = "/item";
  };

  const itemsPerPage = 12;
  let endOffset = itemOffset + itemsPerPage;
  let currentItems = displayListings.slice(itemOffset, endOffset);
  let pageCount = Math.ceil(displayListings.length / itemsPerPage);

  return (
    <div>
      <ToastContainer />
      <section className="py-5 item-bg position-relative">
        <div id="particles-js"></div>
        <div className="container py-5 mt-5 z-index-20">
          <h1 className="text-center white-color">Marketplace</h1>

          <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-center">
              <li className="breadcrumb-item">
                <a
                  className="text-decoration-none d-flex align-items-center"
                  href="#"
                >
                  {" "}
                  <i className="las la-home la-sm me-1"></i>Home
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Explore
              </li>
            </ol>
          </nav>
        </div>
      </section>

      <div
        class="btn-group flex-column flex-md-row w-100"
        role="group"
        aria-label="Basic radio toggle button group"
      >
        <div class="col-6">
          <input
            class="btn-check"
            id="fixedPrice"
            type="radio"
            name="btnradio"
            checked
          />
          <label
            className={
              "btn m-1 rounded-sm text-gray-400 flex-fill w-100 " +
              (index === 0 ? "btn-info" : "")
            }
            for="fixedPrice"
            onClick={() => filterRecords(0)}
          >
            <i class="text-primary las la-tag me-2"></i>All NFTs
          </label>
        </div>
        <div class="col-6">
          <input
            class="btn-check"
            id="timedAuction"
            type="radio"
            name="btnradio"
            required
          />
          <label
            className={
              "btn m-1 rounded-sm text-gray-400 flex-fill w-100 " +
              (index === 1 ? "btn-info" : "")
            }
            for="timedAuction"
            onClick={() => filterRecords(1)}
          >
            <i class="text-primary las la-hourglass-half me-2"></i>
            My NFTs
          </label>
          <input
            class="btn-check"
            id="openForBids"
            type="radio"
            name="btnradio"
            required
          />
        </div>
        {/* <label
                      class="btn btn-outline-secondary m-1 rounded-sm text-gray-400 flex-fill"
                      for="openForBids"
                    >
                      <i class="text-primary las la-user-friends me-2"></i>Open
                      for Bids{" "}
                    </label> */}
      </div>

      <section className="py-5">
        <div className="container pt-5">
          <header className="mb-4">
            <form action="#">
              <ul
                className="list-inline d-flex align-items-center flex-wrap"
                id="explore-filter"
              >
                <li className="list-inline-item mb-2 ">
                  <div className="input-group rounded bd-3 bg-dark">
                    <input
                      className="form-control border-0 bg-none shadow-0"
                      type="search"
                      name="email"
                      placeholder="Search item here..."
                      onInput={(e) => search(e.target.value)}
                    />
                    <button
                      className="btn btn-primary btn-sm m-1 rounded-sm"
                      type="submit"
                    >
                      <i className="las la-search"></i>
                    </button>
                  </div>
                </li>
                <li className="list-inline-item mb-2 d-none">
                  <select
                    className="all-categories-choices"
                    name="allCategories"
                    data-customclass="form-select border-dark bd-3 bg-dark"
                  >
                    <option value="allCategories">All Categories </option>
                    <option value="art">SafeMars Haloweek </option>
                    <option value="art">SafeMars Space</option>
                    <option value="art">SafeMars V1 </option>
                    <option value="art">Video </option>
                    <option value="music">Music </option>
                    <option value="music">Gif </option>
                    <option value="music">Memes </option>
                    <option value="music">Art </option>
                    <option value="virtual worlds">Virtual Worlds </option>
                    <option value="trading cards">Trading Cards </option>
                    <option value="collectibles">Collectibles </option>
                    <option value="utility">Utility </option>
                  </select>
                </li>
                <li className="list-inline-item mb-2 d-none">
                  <select
                    className="all-auctions-choices"
                    name="allAuctions"
                    data-customclass="form-select border-dark bd-3 bg-dark"
                  >
                    <option value="allAcutions">All Auctions </option>
                    <option value="buy now">Buy Now </option>
                    <option value="has offer">Has Offer </option>
                  </select>
                </li>
                <li className="list-inline-item mb-2 d-none">
                  <select
                    className="all-items-choices"
                    name="allItems"
                    data-customclass="form-select border-dark bd-3 bg-dark"
                  >
                    <option value="allItems">All Items </option>
                    <option value="single items">Single Items </option>
                    <option value="bundles">Bundles </option>
                  </select>
                </li>
              </ul>
            </form>
          </header>

          <div className="row gy-4 mb-5">
            <div className="d-flex justify-content-center">
              <ClipLoader
                color={"#ffffff"}
                loading={loading}
                size={80}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
            <span className={currentItems.length === 0 ? "" : "d-none"}>
              No Item to display
            </span>

            {currentItems.map(function (data, i) {
              return (
                <div className="col-xl-3 col-lg-4 col-md-6" key={i}>
                  <div className="card rounded shadow-sm card-hover-image">
                    <div className="card-body p-3 position-relative">
                      <div className="position-relative mb-4 shadow">
                        <div className="author z-index-20">
                          {/* <img
                            className="img-fluid rounded-circle"
                            src={image1}
                            alt="..."
                            width="45"
                          /> */}
                          <span className="icon bg-primary text-white">
                            <i className="las la-check-double"></i>
                          </span>
                        </div>

                        <div className="card-img-holder rounded overflow-hidden">
                          {data.image && (
                            <img
                              class="img-fluid rounded w-100"
                              src={data.image}
                              alt=""
                            />
                          )}
                          {data.media_type && data.media_type === "video" && (
                            <video
                              width="320"
                              height="240"
                              controlsList="nodownload"
                              controls
                            >
                              <source
                                src={data.animation_url}
                                type={data.animation_type}
                              />
                              Your browser does not support the video element.
                            </video>
                          )}

                          {data.media_type && data.media_type === "audio" && (
                            <audio controlsList="nodownload" controls>
                              <source
                                src={data.animation_url}
                                type={data.animation_type}
                              />
                              Your browser does not support the audio element.
                            </audio>
                          )}
                        </div>
                      </div>
                      <p className="fw-bold mb-3"> {data.name}</p>
                      <p className="text-sm text-muted fw-normal mb-2 d-flex align-items-center">
                        <span className="icon bg-primary text-white me-2">
                          <i className="lab la-bitcoin fa-fw"></i>
                        </span>
                        <span>Price: </span>
                        <span className="text-primary ms-2">
                          {" "}
                          {data.price} {process.env.REACT_APP_TOKEN_SYMBOL}
                        </span>
                        {/* <span class="ml-4">{data.tokenId}/</span> */}
                        {/* <span className="ms-2">1/20</span> */}
                      </p>
                      <div class="my-3 pt-1 bg-body rounded-pill"></div>

                      <ul class="list-unstyled d-flex align-items-center justify-content-between fw-normal text-sm text-muted mb-0">
                        <li class="d-flex align-items-center">
                          <i class="la-sm text-primary las la-clock me-2 text-primary"></i>
                          {data.timestamp}
                        </li>
                        <li class="d-flex align-items-center d-none">
                          <i class="la-sm text-primary las la-heart me-2 text-primary"></i>
                          42 Likes
                        </li>
                      </ul>

                      {/* <Link
                        to={{
                          // pathname: `/item?type=2&address=${data.address}`,
                          pathname: `/item`,
                          nftData: data,
                          type: 1,
                        }}
                      >
                        <button class="btn btn-primary btn-sm w-100 mt-4">
                          View Item
                        </button>
                      </Link> */}

                      <button
                        onClick={() => showItem(data, 1)}
                        class="btn btn-primary btn-sm w-100 mt-4"
                      >
                        View Item
                      </button>

                      {}

                      {data.isOwner && (
                        <button
                          class="btn btn-secondary btn-sm w-100 mt-2"
                          onClick={() => cancelTrade(data)}
                        >
                          Cancel Trade
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <ReactPaginate
            breakLabel="..."
            nextLabel="next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination mb-0 justify-content-center"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
      </section>
    </div>
  );
}

export default MarketPlace;
