import React, { useState, useEffect } from "react";
import { useParams, useLocation, Redirect } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import MarketplaceContractJSON from "../contracts/Marketplace.json";
import AuctionManagerContractJSON from "../contracts/AuctionManager.json";
import SafeMarsNFTContractJSON from "../contracts/SafeMarsNFT.json";
import SafeMarsTokenContractJSON from "../contracts/SafeMarsToken.json";

function Create({ web3 }) {
  const { nftData } = useLocation();
  const { account, library, active } = useWeb3React();
  const [message, setMessage] = useState("");
  const [index, setIndex] = useState(0);

  if (!nftData || !active) {
    window.location = "/myNfts";
  }

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    // setMessage("Listing...");

    const safeMarsTokenContract = new library.eth.Contract(
      SafeMarsTokenContractJSON.abi,
      process.env.REACT_APP_SAFEMARS_TOKEN
    );

    const safeMarsNFTContract = new library.eth.Contract(
      SafeMarsNFTContractJSON.abi,
      process.env.REACT_APP_SAFEMARS_NFT
    );

    const marketplaceContract = new library.eth.Contract(
      MarketplaceContractJSON.abi,
      process.env.REACT_APP_MARKET_PLACE
    );

    const auctionManagerContract = new library.eth.Contract(
      AuctionManagerContractJSON.abi,
      process.env.REACT_APP_AUCTION_MANAGER
    );

    const customNFTABI = [
      {
        inputs: [
          {
            internalType: "address",
            name: "to",
            type: "address",
          },
          {
            internalType: "uint256",
            name: "tokenId",
            type: "uint256",
          },
        ],
        name: "approve",
        outputs: [],
        stateMutability: "nonpayable",
        type: "function",
      },
    ];

    const customNFTContract = new library.eth.Contract(
      customNFTABI,
      nftData.contractAddress
    );

    const data = new FormData(evt.target);

    //Handle markeplace
    if (index === 0) {
      let price = data.get("price");
      try {
        let listingFee = await marketplaceContract.methods
          .getListingFee()
          .call();

        if (price <= 0) {
          setMessage("Enter a valid price");
          return;
        }

        price = ethers.utils.parseEther(price);

        if (price <= listingFee) {
          setMessage(`Price must be more than the listing fee`);
          return;
        }

        // console.log("listing: " + listingFee);
        // console.log("price: " + price);
        // console.log("nft ", process.env.REACT_APP_SAFEMARS_NFT);
        // console.log("Nft Token ID ", nftData.tokenID);

        //?????? Important
        if (listingFee > 0) {
          await toast.promise(
            safeMarsTokenContract.methods
              .approve(process.env.REACT_APP_MARKET_PLACE, listingFee)
              .send({ from: account }),
            {
              pending: "Approving...",
              success: "Success",
              error: "Failed to approve",
            }
          );
        }

        await toast.promise(
          customNFTContract.methods
            .approve(process.env.REACT_APP_MARKET_PLACE, nftData.tokenID)
            .send({ from: account }),
          {
            pending: "Approving token transfer...",
            success: "Success",
            error: "Failed to approve nft transfer",
          }
        );

        await toast.promise(
          marketplaceContract.methods
            .listNFT(nftData.contractAddress, nftData.tokenID, price)
            .send({ from: account }),
          {
            pending: "Listing on marketplace...",
            success: "Success",
            error: "Failed to list",
          }
        );
        // setMessage("NFT listed on marketplace successfully");
      } catch (err) {
        // setMessage("Failed to list in marketplace");
        console.log(err);
      }
    }

    //Handle auction
    if (index === 1) {
      const startPrice = data.get("startPrice").toString();
      const minimumIncrement = data.get("minimumIncrement").toString();
      const directBuyPrice = data.get("directBuyPrice").toString();

      const from = moment(new Date());
      const to = moment(data.get("endDate"));
      const distance = moment.duration(to.diff(from));
      const secs = distance.asSeconds();

      if (secs <= 0) setMessage("Please enter a valid time duration");

      try {
        //?????? Important

        let listingFee = await auctionManagerContract.methods
          .getListingFee()
          .call();

        if (listingFee > 0) {
          await toast.promise(
            safeMarsTokenContract.methods
              .approve(process.env.REACT_APP_AUCTION_MANAGER, listingFee)
              .send({ from: account }),
            {
              pending: "Approving...",
              success: "Success",
              error: "Failed to approve",
            }
          );
        }

        await toast.promise(
          customNFTContract.methods
            .approve(process.env.REACT_APP_AUCTION_MANAGER, nftData.tokenID)
            .send({ from: account }),
          {
            pending: "Approving token transfer...",
            success: "Success",
            error: "Failed to approve nft transfer",
          }
        );

        await toast.promise(
          auctionManagerContract.methods
            .createAuction(
              Math.floor(secs),
              ethers.utils.parseEther(minimumIncrement),
              ethers.utils.parseEther(directBuyPrice),
              ethers.utils.parseEther(startPrice),
              nftData.contractAddress,
              nftData.tokenID
            )
            .send({ from: account }),
          {
            pending: "Listing on auction...",
            success: "Success",
            error: "Failed to list",
          }
        );
        // setMessage("Auction created successfully");
      } catch (err) {
        // setMessage("Failed to create sales auction");
        console.log(err);
      }
    }
  };

  return (
    <div>
      <ToastContainer />
      <section class="py-5">
        <div class="container py-5">
          <div class="row g-5">
            <div class="col-lg-8">
              {/* <div class="d-flex align-items-center mb-4">
                <div class="icon icon-md me-2 flex-shrink-0 bg-primary rounded-sm text-white">
                  <i class="las la-cloud-upload-alt"></i>
                </div>
                <h2 class="h5 mb-0 white-color">Upload file</h2>
              </div>

              <form
                class="dropzone rounded bg-dark d-flex justify-content-center mb-5"
                id="demo-upload"
                action="/upload"
              >
                <div class="dz-message py-5">
                  <i class="las la-image la-3x text-muted"></i>
                  <p class="text-muted">Drop files here or click to upload.</p>
                </div>
              </form> */}

              <div class="d-flex align-items-center mb-4">
                <div class="icon icon-md me-2 flex-shrink-0 bg-primary rounded-sm text-white">
                  <i class="las la-th-large"></i>
                </div>
                <h2 class="h5 mb-0 white-color">Select Method</h2>
              </div>

              <form class="p-4 p-lg-5 bg-dark" onSubmit={handleSubmit}>
                <div class="item-type">
                  <div
                    class="btn-group flex-column flex-md-row w-100"
                    role="group"
                    aria-label="Basic radio toggle button group"
                  >
                    <div class="col-6">
                      <input
                        class="btn-check"
                        id="fixedPrice"
                        type="radio"
                        name="btnradio"
                        checked
                      />
                      <label
                        className={
                          "btn m-1 rounded-sm text-gray-400 flex-fill w-100 " +
                          (index === 0 ? "btn-info" : "")
                        }
                        for="fixedPrice"
                        onClick={() => setIndex(0)}
                      >
                        <i class="text-primary las la-tag me-2"></i>Fixed Price
                      </label>
                    </div>
                    <div class="col-6">
                      <input
                        class="btn-check"
                        id="timedAuction"
                        type="radio"
                        name="btnradio"
                        required
                      />
                      <label
                        className={
                          "btn m-1 rounded-sm text-gray-400 flex-fill w-100 " +
                          (index === 1 ? "btn-info" : "")
                        }
                        for="timedAuction"
                        onClick={() => setIndex(1)}
                      >
                        <i class="text-primary las la-hourglass-half me-2"></i>
                        Timed Auction
                      </label>
                      <input
                        class="btn-check"
                        id="openForBids"
                        type="radio"
                        name="btnradio"
                        required
                      />
                    </div>
                    {/* <label
                      class="btn btn-outline-secondary m-1 rounded-sm text-gray-400 flex-fill"
                      for="openForBids"
                    >
                      <i class="text-primary las la-user-friends me-2"></i>Open
                      for Bids{" "}
                    </label> */}
                  </div>
                </div>

                <div class="row gy-3 py-4">
                  <div
                    className={
                      "col-12 " + (index === 0 ? "d-block " : "d-none")
                    }
                    data-form="fixedPrice"
                  >
                    <div class="row">
                      <div class="col-lg-12">
                        <label class="form-label text-gray-400" for="price">
                          {" "}
                          Price
                        </label>
                        <div class="input-group">
                          <div class="input-group-text border-end-0 pe-0 bd-dark-lighter bg-none">
                            <i class="text-primary las la-hand-holding-usd"></i>
                          </div>
                          <input
                            class="form-control shadow-0 border-start-0 bd-dark-lighter bg-none"
                            type="text"
                            autocomplete="off"
                            name="price"
                            id="price"
                            placeholder="Enter price for one item"
                            required={index === 0}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={"col-12 " + (index === 1 ? "d-block" : "d-none")}
                    data-form="timedAuction"
                  >
                    <div class="row gy-3">
                      <div class="col-lg-12">
                        <label
                          class="form-label text-gray-400"
                          for="startPrice"
                        >
                          {" "}
                          Start Price
                        </label>
                        <div class="input-group">
                          <div class="input-group-text border-end-0 pe-0 bd-dark-lighter bg-none">
                            <i class="text-primary las la-hand-holding-usd"></i>
                          </div>
                          <input
                            class="form-control shadow-0 border-start-0 bd-dark-lighter bg-none"
                            type="number"
                            step=".01"
                            autocomplete="off"
                            name="startPrice"
                            id="startPrice"
                            placeholder="Enter start bid price"
                            required={index === 1}
                          />
                        </div>
                      </div>

                      <div class="col-lg-12">
                        <label
                          class="form-label text-gray-400"
                          for="minimumIncrement"
                        >
                          {" "}
                          Minimum Increment
                        </label>
                        <div class="input-group">
                          <div class="input-group-text border-end-0 pe-0 bd-dark-lighter bg-none">
                            <i class="text-primary las la-hand-holding-usd"></i>
                          </div>
                          <input
                            class="form-control shadow-0 border-start-0 bd-dark-lighter bg-none"
                            type="number"
                            step=".01"
                            autocomplete="off"
                            name="minimumIncrement"
                            id="minimumIncrement"
                            placeholder="Enter minimum increment of every bid"
                            required={index === 1}
                          />
                        </div>
                      </div>

                      <div class="col-lg-12">
                        <label
                          class="form-label text-gray-400"
                          for="directBuyPrice"
                        >
                          {" "}
                          Direct Buy Price
                        </label>
                        <div class="input-group">
                          <div class="input-group-text border-end-0 pe-0 bd-dark-lighter bg-none">
                            <i class="text-primary las la-hand-holding-usd"></i>
                          </div>
                          <input
                            class="form-control shadow-0 border-start-0 bd-dark-lighter bg-none"
                            type="number"
                            step=".01"
                            autocomplete="off"
                            name="directBuyPrice"
                            id="directBuyPrice"
                            placeholder="Enter direct buy price"
                            required={index === 1}
                          />
                        </div>
                      </div>

                      <div class="col-lg-12">
                        <label class="form-label text-gray-400" for="endDate">
                          {" "}
                          End Date
                        </label>
                        <div class="input-group">
                          <div class="input-group-text border-end-0 pe-0 bd-dark-lighter bg-none">
                            <i class="text-primary las la-hand-holding-usd"></i>
                          </div>
                          <input
                            class="form-control shadow-0 border-start-0 bd-dark-lighter bg-none"
                            type="datetime-local"
                            autocomplete="off"
                            name="endDate"
                            id="endDate"
                            placeholder="Enter auction end date"
                            required={index === 1}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div class="col-12 mb-2" data-form="openForBids">
                    <div class="row gy-3">
                      <div class="col-lg-12">
                        <label class="form-label text-gray-400" for="title">
                          {" "}
                          Title
                        </label>
                        <div class="input-group">
                          <div class="input-group-text border-end-0 pe-0 bd-dark-lighter bg-none">
                            <i class="text-primary las la-user-edit"></i>
                          </div>
                          <input
                            class="form-control shadow-0 border-start-0 bd-dark-lighter bg-none"
                            type="text"
                            autocomplete="off"
                            name="title"
                            id="title"
                            placeholder="e.g. Crypto Funk"
                          />
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <label
                          class="form-label text-gray-400"
                          for="description"
                        >
                          {" "}
                          Description
                        </label>
                        <textarea
                          class="form-control shadow-0 bd-dark-lighter bg-none"
                          rows="3"
                          name="description"
                          id="description"
                          placeholder="e.g. This is a very cool item"
                        ></textarea>
                      </div>
                      <div class="col-lg-12">
                        <label class="form-label text-gray-400" for="royalties">
                          {" "}
                          Royalties
                        </label>
                        <div class="input-group">
                          <div class="input-group-text border-end-0 pe-0 bd-dark-lighter bg-none">
                            <i class="text-primary las la-percentage"></i>
                          </div>
                          <input
                            class="form-control shadow-0 border-start-0 bd-dark-lighter bg-none"
                            type="text"
                            autocomplete="off"
                            name="royalties"
                            id="royalties"
                            placeholder="Suggested: 0, 10%, 20%, 30%, maximum 70%"
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div class="col-12">
                    <button class="btn btn-primary" type="submit">
                      <i class="las la-plus-circle me-2"></i>Create Item
                    </button>
                    <br /> <br />
                    <span>{message}</span>
                  </div>
                </div>
              </form>
            </div>

            <div class="col-lg-4">
              <div class="d-flex align-items-center mb-4">
                <div class="icon icon-md me-2 flex-shrink-0 bg-primary rounded-sm text-white">
                  <i class="las la-eye"></i>
                </div>
                <h2 class="h5 mb-0">Preview Item</h2>
              </div>

              <div class="card rounded shadow-sm">
                <div class="card-body p-3 position-relative">
                  <div class="position-relative mb-4 shadow">
                    <div class="author z-index-20">
                      {/* <img
                        class="img-fluid rounded-circle"
                        src={image1}
                        alt="..."
                        width="45"
                      /> */}
                      <span class="icon bg-primary text-white">
                        <i class="las la-check-double"></i>
                      </span>
                    </div>
                    <div class="card-img-holder rounded overflow-hidden">
                      {nftData.image && (
                        <img
                          class="img-fluid rounded w-100"
                          src={nftData.image}
                          alt=""
                        />
                      )}

                      {nftData.media_type && nftData.media_type === "video" && (
                        <video
                          width="320"
                          height="240"
                          controlsList="nodownload"
                          controls
                        >
                          <source
                            src={nftData.animation_url}
                            type={nftData.animation_type}
                          />
                          Your browser does not support the video element.
                        </video>
                      )}

                      {nftData.media_type && nftData.media_type === "audio" && (
                        <audio controlsList="nodownload" controls>
                          <source
                            src={nftData.animation_url}
                            type={nftData.animation_type}
                          />
                          Your browser does not support the audio element.
                        </audio>
                      )}
                    </div>
                  </div>
                  <p class="fw-bold mb-3">{nftData.name}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Create;
