import React, { useState, useEffect } from "react";
import { useWeb3React } from "@web3-react/core";
import { Link } from "react-router-dom";
import { ethers } from "ethers";
import moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import image1 from "../img/authors/author-1.jpg";
import item1 from "../img/items/item-1.jpg";
import item2 from "../img/items/item-2.jpg";
import item3 from "../img/items/item-3.jpg";
import item9 from "../img/items/item-9.jpg";
import item5 from "../img/items/item-5.jpg";
import item6 from "../img/items/item-6.jpg";
import item7 from "../img/items/item-7.jpg";
import item8 from "../img/items/item-8.jpg";

import AuctionContractJSON from "../contracts/Auction.json";
import AuctionManagerContractJSON from "../contracts/AuctionManager.json";
import SafeMarsNFTContractJSON from "../contracts/SafeMarsNFT.json";

function Auctions({ web3 }) {
  const { account, library, active } = useWeb3React();
  const [auctions, setAuctions] = useState([]);
  const [displayAuctions, setDisplayAuctions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [itemOffset, setItemOffset] = useState(0);
  const [index, setIndex] = useState(0);

  const itemsPerPage = 12;
  const endOffset = itemOffset + itemsPerPage;
  let currentItems = displayAuctions.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(displayAuctions.length / itemsPerPage);

  useEffect(async () => {
    setAuctions([]);
    await getAuctions();
  }, []);

  useEffect(async () => {
    if (active) {
      auctions.forEach((data) => {
        data.isOwner = data.owner === account;
        data.showCancelAuction =
          data.isOwner && !data.isAuctionEnded && data.highestBid <= 0;

        data.showWithdrawFund = false;
        data.showWithdrawToken = false;

        if (
          data.isAuctionEnded &&
          data.isOwner &&
          data.highestBidder !== account
        ) {
          data.showWithdrawFund = true;
          data.showWithdrawToken = false;
        } else if (
          data.isAuctionEnded &&
          data.highestBidder === account &&
          !data.isOwner
        ) {
          data.showWithdrawFund = false;
          data.showWithdrawToken = true;
        } else {
          data.showWithdrawFund = false;
          data.showWithdrawToken = false;
        }
      });
    }
  }, [auctions]);

  const getAuctions = async () => {
    const auctionManagerContract = new web3.eth.Contract(
      AuctionManagerContractJSON.abi,
      process.env.REACT_APP_AUCTION_MANAGER
    );

    const safeMarsNFTContract = new web3.eth.Contract(
      SafeMarsNFTContractJSON.abi,
      process.env.REACT_APP_SAFEMARS_NFT
    );
    try {
      const auctionAddresses = await auctionManagerContract.methods
        .getAuctions()
        .call();

      if (auctionAddresses.length === 0) {
        setLoading(false);
        return;
      }

      let auctions = await auctionManagerContract.methods
        .getAuctionInfo(auctionAddresses)
        .call();

      const auctions2 = await auctionManagerContract.methods
        .getAuctionInfo2(auctionAddresses)
        .call();

      for (let i = 0; i < auctions.endTime.length; i++) {
        if (auctions.owner[i] == 0) continue;
        const auctionAddress = auctions.auctionAddress[i];
        const endTime = auctions.endTime[i];
        const tokenId = auctions.tokenIds[i];
        const buyerWithdrawn = auctions.buyerWithdrawn[i];
        const bidderWithdrawn = auctions.bidderWithdrawn[i];
        const auctionState = +auctions.auctionState[i];
        const startPrice = ethers.utils.formatEther(auctions.startPrice[i]);
        const directBuyPrice = ethers.utils.formatEther(auctions.directBuy[i]);
        const highestBidder = auctions.highestBidder[i];
        const highestBid = ethers.utils.formatEther(auctions.highestBid[i]);
        const address = auctionAddresses[i];
        const owner = auctions.owner[i];
        const timestamp = auctions.startTime[i];
        const contractAddress = auctions2.contractAddress[i];
        const minIncrement = auctions2.minIncrement[i];

        let state = "";
        if (auctionState === 0) {
          state = "Open";
        }
        if (auctionState === 1) {
          state = "Cancelled";
        }
        if (auctionState === 2) {
          state = "Ended";
        }
        if (auctionState === 3) {
          state = "Direct Buy";
        }

        const tokenUri = await safeMarsNFTContract.methods
          .tokenURI(parseInt(tokenId))
          .call();

        const listing = await fetch(tokenUri);

        const resp = await listing.json();

        resp.dateTime = resp.attributes[1].value;

        const from = moment(new Date());
        const to = moment.utc(1000 * endTime);

        const duration = moment.duration(to.diff(from));

        let days = duration.days();
        let hours = duration.hours();
        let minutes = duration.minutes();
        let seconds = duration.seconds();
        let stopBid = false;
        let isAuctionEnded =
          state === "Ended" || state === "Direct Buy" || state === "Cancelled";

        if (isAuctionEnded || seconds <= 0) {
          days = 0;
          hours = 0;
          minutes = 0;
          seconds = 0;
          stopBid = true;
        }

        const isOwner = owner === account;

        const showCancelAuction = isOwner && !isAuctionEnded && highestBid <= 0;

        let showWithdrawFund = false;
        let showWithdrawToken = false;

        if (isAuctionEnded && isOwner && highestBidder !== account) {
          showWithdrawFund = true;
          showWithdrawToken = false;
        } else if (isAuctionEnded && highestBidder === account && !isOwner) {
          showWithdrawFund = false;
          showWithdrawToken = true;
        } else {
          showWithdrawFund = false;
          showWithdrawToken = false;
        }

        const startPriceEth = ethers.utils.parseEther(startPrice);
        let minimumBid = +startPriceEth.toString() + +minIncrement;
        minimumBid = ethers.utils.formatEther("" + minimumBid);

        let data = {
          address,
          auctionAddress,
          contractAddress,
          highestBidder,
          buyerWithdrawn,
          bidderWithdrawn,
          endTime,
          stopBid,
          startPrice,
          minIncrement,
          created: moment(resp.dateTime).format("MM/DD/yyyy, h:mm:ss a") ?? "",
          owner,
          isOwner,
          directBuyPrice,
          tokenId,
          highestBid,
          auctionState: state,
          name: resp.name,
          description: resp.description,
          date: {
            days: days,
            hours: hours,
            minutes: minutes,
            seconds: seconds,
          },
          timestamp: moment(timestamp * 1000).fromNow() ?? "",
          auctionEnded: isAuctionEnded,
          showWithdrawFund,
          showWithdrawToken,
          showCancelAuction,
          isAuctionEnded,
          minimumBid,
        };

        if (resp.image) data.image = resp.image;
        if (resp.animation_url) data.animation_url = resp.animation_url;
        if (resp.animation_type) {
          data.animation_type = resp.animation_type;
          data.media_type = data.animation_type.split("/")[0];
        }

        setAuctions((oldArray) => {
          const newArray = [...oldArray];
          newArray.push(data);

          return newArray;
        });
        if (data.auctionState === "Open") {
          setDisplayAuctions((oldArray) => {
            const newArray = [...oldArray];
            newArray.push(data);

            return newArray;
          });
        }
      }
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  const cancelAuction = async (data) => {
    const auctionContract = new library.eth.Contract(
      AuctionContractJSON.abi,
      data.address
    );

    await toast.promise(
      auctionContract.methods.cancelAuction().send({ from: account }),
      {
        pending: "Cancelling...",
        success: "Success",
        error: "Failed to cancel",
      }
    );
  };

  const search = async (value) => {
    const _auctions = [];

    for (let auction of auctions) {
      if (
        !auction.isOwner &&
        auction.highestBidder !== account &&
        auction.isAuctionEnded
      ) {
        continue;
      }
      _auctions.push(auction);
    }

    const data = _auctions.filter((auction) =>
      auction.name.toLowerCase().includes(value.toLowerCase())
    );

    setDisplayAuctions(data);
  };

  const filterRecords = (index) => {
    setIndex(index);
    if (index === 0) {
      const data = auctions.filter(
        (auction) => auction.auctionState === "Open"
      );

      setDisplayAuctions(data);
    }

    if (index === 1) {
      const data = auctions.filter(
        (auction) =>
          auction.owner === account || auction.highestBidder == account
      );
      setDisplayAuctions(data);
    }
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % displayAuctions.length;
    setItemOffset(newOffset);
  };

  const withdrawFund = async (data) => {
    const auctionContract = new library.eth.Contract(
      AuctionContractJSON.abi,
      data.address
    );

    await toast.promise(
      auctionContract.methods.withdrawFunds().send({ from: account }),
      {
        pending: "Withdrawing...",
        success: "Success",
        error: "Failed to withdraw funds",
      }
    );
  };

  const withdrawToken = async (data) => {
    const auctionContract = new library.eth.Contract(
      AuctionContractJSON.abi,
      data.address
    );

    await toast.promise(
      auctionContract.methods.withdrawToken().send({ from: account }),
      {
        pending: "Withdrawing...",
        success: "Success",
        error: "Failed to withdraw funds",
      }
    );
  };

  const showItem = (data, type) => {
    data.isOwner = data.owner === account;
    localStorage.setItem("nftData", JSON.stringify(data));
    localStorage.setItem("type", type);
    window.location = "/item";
  };

  return (
    <div>
      <ToastContainer />
      <section class="py-5  item-bg position-relative">
        <div id="particles-js"></div>
        <div class="container py-5 mt-5 z-index-20">
          <h1 class="text-center white-color">Live Auctions</h1>

          <nav aria-label="breadcrumb">
            <ol class="breadcrumb justify-content-center">
              <li class="breadcrumb-item">
                <a
                  class="text-decoration-none d-flex align-items-center"
                  href="#"
                >
                  {" "}
                  <i class="las la-home la-sm me-1"></i>Home
                </a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Live Auctions
              </li>
            </ol>
          </nav>
        </div>
      </section>

      <div
        class="btn-group flex-column flex-md-row w-100"
        role="group"
        aria-label="Basic radio toggle button group"
      >
        <div class="col-6">
          <input
            class="btn-check"
            id="fixedPrice"
            type="radio"
            name="btnradio"
            checked
          />
          <label
            className={
              "btn m-1 rounded-sm text-gray-400 flex-fill w-100 " +
              (index === 0 ? "btn-info" : "")
            }
            for="fixedPrice"
            onClick={() => filterRecords(0)}
          >
            <i class="text-primary las la-tag me-2"></i>All Actions
          </label>
        </div>
        <div class="col-6">
          <input
            class="btn-check"
            id="timedAuction"
            type="radio"
            name="btnradio"
            required
          />
          <label
            className={
              "btn m-1 rounded-sm text-gray-400 flex-fill w-100 " +
              (index === 1 ? "btn-info" : "")
            }
            for="timedAuction"
            onClick={() => filterRecords(1)}
          >
            <i class="text-primary las la-hourglass-half me-2"></i>
            My Auctions
          </label>
          <input
            class="btn-check"
            id="openForBids"
            type="radio"
            name="btnradio"
            required
          />
        </div>
        {/* <label
                      class="btn btn-outline-secondary m-1 rounded-sm text-gray-400 flex-fill"
                      for="openForBids"
                    >
                      <i class="text-primary las la-user-friends me-2"></i>Open
                      for Bids{" "}
                    </label> */}
      </div>

      <section class="py-5">
        <div class="container py-5">
          <header className="mb-4">
            <form action="#">
              <ul
                className="list-inline d-flex align-items-center flex-wrap"
                id="explore-filter"
              >
                <li className="list-inline-item mb-2 ">
                  <div className="input-group rounded bd-3 bg-dark">
                    <input
                      className="form-control border-0 bg-none shadow-0"
                      type="search"
                      name="email"
                      placeholder="Search item here..."
                      onInput={(e) => search(e.target.value)}
                    />
                    <button
                      className="btn btn-primary btn-sm m-1 rounded-sm"
                      type="submit"
                    >
                      <i className="las la-search"></i>
                    </button>
                  </div>
                </li>
              </ul>
            </form>
          </header>
          <div class="row gy-4 mb-5">
            <div className="d-flex justify-content-center">
              <ClipLoader
                color={"#ffffff"}
                loading={loading}
                size={80}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
            <span className={currentItems.length === 0 ? "" : "d-none"}>
              No Item to display
            </span>
            {currentItems.map(function (data, i) {
              return (
                <div key={i} class="col-xl-3 col-lg-4 col-md-6">
                  <div class="card rounded shadow-sm">
                    <div class="card-body p-3 position-relative">
                      <div class="position-relative mb-4 shadow">
                        <div class="author z-index-20">
                          <span class="icon bg-primary text-white">
                            <i class="las la-check-double"></i>
                          </span>
                        </div>

                        <div class="card-img-holder rounded overflow-hidden">
                          {data.image && (
                            <img
                              class="img-fluid rounded w-100"
                              src={data.image}
                              alt=""
                            />
                          )}
                          {data.media_type && data.media_type === "video" && (
                            <video
                              width="200"
                              height="200"
                              controlsList="nodownload"
                              controls
                            >
                              <source
                                src={data.animation_url}
                                type={data.animation_type}
                              />
                              Your browser does not support the video element.
                            </video>
                          )}

                          {data.media_type && data.media_type === "audio" && (
                            <audio controlsList="nodownload" controls>
                              <source
                                src={data.animation_url}
                                type={data.animation_type}
                              />
                              Your browser does not support the audio element.
                            </audio>
                          )}
                        </div>
                      </div>
                      <p class="fw-bold mb-3">
                        <a class="text-reset" href="item">
                          {data.name}
                        </a>
                      </p>
                      <p class="text-sm text-muted fw-normal mb-2 d-flex align-items-center">
                        <span class="icon bg-primary text-white me-2">
                          <i class="lab la-bitcoin fa-fw"></i>
                        </span>
                        <span>Start Price: </span>
                        <span class="text-primary ms-2">
                          {data.startPrice} {process.env.REACT_APP_TOKEN_SYMBOL}
                        </span>
                      </p>
                      <p class="text-sm text-muted fw-normal mb-2 d-flex align-items-center">
                        <span class="icon bg-primary text-white me-2">
                          <i class="lab la-bitcoin fa-fw"></i>
                        </span>
                        <span>Direct Buy: </span>
                        <span class="text-primary ms-2">
                          {data.directBuyPrice}{" "}
                          {process.env.REACT_APP_TOKEN_SYMBOL}
                        </span>
                      </p>
                      <p class="text-sm text-muted fw-normal mb-2 d-flex align-items-center">
                        <span class="icon bg-primary text-white me-2">
                          <i class="lab la-bitcoin fa-fw"></i>
                        </span>
                        <span>Highest Bid: </span>
                        <span class="text-primary ms-2">
                          {data.highestBid} {process.env.REACT_APP_TOKEN_SYMBOL}
                        </span>
                      </p>
                      <p class="text-sm text-muted fw-normal mb-2 d-flex align-items-center">
                        <span class="icon bg-primary text-white me-2">
                          <i class="lab la-bitcoin fa-fw"></i>
                        </span>
                        <span>Minimum Bid: </span>
                        <span class="text-primary ms-2">
                          {data.minimumBid} {process.env.REACT_APP_TOKEN_SYMBOL}
                        </span>
                      </p>
                      <p class="text-sm text-muted fw-normal mb-2 d-flex align-items-center">
                        <span class="icon bg-primary text-white me-2">
                          <i class="lab la-bitcoin fa-fw"></i>
                        </span>
                        <span>Auction State: </span>
                        <span class="text-primary ms-2">
                          {data.auctionState}
                        </span>
                      </p>
                      <div
                        class="p-2 counter d-flex align-items-center justify-content-center text-center counter-1"
                        data-date="14-12-2021"
                        data-time="12:00"
                      >
                        <div class="day counter-item">
                          <strong class="num">{data.date.days}</strong>
                          <small class="small word">Days</small>
                        </div>
                        <div class="hour counter-item">
                          <strong class="num">{data.date.hours}</strong>
                          <small class="small word">Hours</small>
                        </div>
                        <div class="min counter-item">
                          <strong class="num">{data.date.minutes}</strong>
                          <small class="small word">Minutes</small>
                        </div>
                        <div class="sec counter-item">
                          <strong class="num">{data.date.seconds}</strong>
                          <small class="small word">Seconds</small>
                        </div>
                      </div>
                      <div class="my-3 pt-1 bg-body rounded-pill"></div>
                      <span style={{ color: "#fff" }}>{data.timestamp}</span>
                      {/* <Link
                        to={{
                          // pathname: `/item?type=2&address=${data.address}`,
                          pathname: `/item`,
                          nftData: data,
                          type: 2,
                        }}
                      >
                        <button
                          class="btn btn-primary btn-sm w-100 mt-4"
                          href=""
                        >
                          View Auction
                        </button>
                      </Link> */}

                      <button
                        onClick={() => showItem(data, 2)}
                        class="btn btn-primary btn-sm w-100 mt-4"
                      >
                        View Auction
                      </button>

                      {data.showCancelAuction ? (
                        <button
                          class="btn btn-danger btn-sm w-100 mt-2"
                          onClick={() => cancelAuction(data)}
                        >
                          Cancel Auction
                        </button>
                      ) : (
                        <></>
                      )}
                      {!data.buyerWithdrawn && data.showWithdrawFund ? (
                        <button
                          class="btn btn-secondary btn-sm w-100 mt-2"
                          onClick={() => withdrawFund(data)}
                        >
                          Withdraw Fund
                        </button>
                      ) : (
                        <></>
                      )}

                      {!data.bidderWithdrawn && data.showWithdrawToken ? (
                        <button
                          class="btn btn-secondary btn-sm w-100 mt-2"
                          onClick={() => withdrawToken(data)}
                        >
                          Withdraw Token
                        </button>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <ReactPaginate
            breakLabel="..."
            nextLabel="next"
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination mb-0 justify-content-center"
            activeClassName="active"
            renderOnZeroPageCount={null}
          />
        </div>
      </section>
    </div>
  );
}

export default Auctions;
