import React, { useState, useEffect, useCallback } from "react";
import {
  useParams,
  useLocation,
  Redirect,
  useHistory,
  Route,
} from "react-router-dom";
import { useWeb3React } from "@web3-react/core";

import { ethers } from "ethers";

import truncateEthAddress from "truncate-eth-address";

import {
  FileSearchOutlined,
  RightCircleOutlined,
  ShoppingCartOutlined,
} from "@ant-design/icons";

import { useWeb3ExecuteFunction } from "react-moralis";
import image1 from "../img/item-lg.jpg";
import image2 from "../img/authors/author-1.jpg";

import MarketplaceContractJSON from "../contracts/Marketplace.json";
import SafeMarsNFTContractJSON from "../contracts/SafeMarsNFT.json";
import SafeMarsTokenContractJSON from "../contracts/SafeMarsToken.json";
import AuctionContractJSON from "../contracts/Auction.json";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Item({ web3 }) {
  let { type, nftData } = useLocation();

  if (!type || !nftData) {
    nftData = localStorage.getItem("nftData");
    nftData = JSON.parse(nftData);
    type = localStorage.getItem("type");
    type = +type;

    // window.location = "/";
  }

  const { redirect, setRedirect } = useState(false);

  const { account, library, active } = useWeb3React();
  const [bids, setBids] = useState([]);
  const [message, setMessage] = useState("");
  const [bidded, setBidded] = useState(false);

  const history = useHistory();

  useEffect(async () => {
    if (type == 2) getBids();
  }, []);

  const buyNft = async (data) => {
    const marketplaceContract = new library.eth.Contract(
      MarketplaceContractJSON.abi,
      process.env.REACT_APP_MARKET_PLACE
    );

    const safeMarsTokenContract = new library.eth.Contract(
      SafeMarsTokenContractJSON.abi,
      process.env.REACT_APP_SAFEMARS_TOKEN
    );

    try {
      let price = ethers.utils.parseEther("" + data.price);
      price = price.toString();

      //?????? Important
      await toast.promise(
        safeMarsTokenContract.methods
          .approve(process.env.REACT_APP_MARKET_PLACE, price)
          .send({ from: account }),
        {
          pending: "Approving...",
          success: "Success",
          error: "Failed to approve",
        }
      );

      await toast.promise(
        marketplaceContract.methods
          .buyNFT(nftData.contractAddress, data.tokenId)
          .send({
            from: account,
          }),
        {
          pending: "Buying...",
          success: "Success",
          error: "Failed to buy",
        }
      );

      window.location = "/myNfts";

      // alert("Purchase Successful");
    } catch (err) {
      console.log(err);
      // alert("Error encountered");
    }
  };

  const getBids = useCallback(async () => {
    const auctionContract = new web3.eth.Contract(
      AuctionContractJSON.abi,
      nftData.address
    );
    const previousBids = await auctionContract.methods.allBids().call();

    setBids([]);

    for (let i = 0; i < previousBids[0].length; i++) {
      (async () => {
        if (previousBids[0][i] === account) setBidded(true);

        const data = {
          bidder: previousBids[0][i], // The bidder
          bid: ethers.utils.formatEther(previousBids[1][i]), // The bid
        };

        setBids((oldArray) => {
          const newArray = [...oldArray];
          newArray.push(data);
          return newArray;
        });
      })();
    }
  }, []);

  const handleBid = async (evt) => {
    evt.preventDefault();
    // setMessage("Bidding..");

    const auctionContract = new library.eth.Contract(
      AuctionContractJSON.abi,
      nftData.address
    );

    const safeMarsTokenContract = new library.eth.Contract(
      SafeMarsTokenContractJSON.abi,
      process.env.REACT_APP_SAFEMARS_TOKEN
    );

    const data = new FormData(evt.target);
    let amount = data.get("amount");

    try {
      if (!amount) return;
      amount = ethers.utils.parseEther(amount);
      let minimumBid = ethers.utils.parseEther(nftData.minimumBid);

      if (+amount.toString() <= minimumBid) {
        setMessage(
          `Bid must be greater than minimum bid (${nftData.minimumBid} ${process.env.REACT_APP_TOKEN_SYMBOL})`
        );
        return;
      }

      //??????? Important
      await toast.promise(
        safeMarsTokenContract.methods
          .approve(nftData.auctionAddress, amount)
          .send({ from: account }),
        {
          pending: "Approving...",
          success: "Success",
          error: "Failed to approve",
        }
      );

      await toast.promise(
        auctionContract.methods.placeBid(amount).send({ from: account }),
        {
          pending: "Bidding...",
          success: "Success",
          error: "Failed to bid",
        }
      );

      setMessage("Success");
      getBids();
    } catch (err) {
      console.log(err);
      setMessage("Failed");
    }
  };

  return (
    <div>
      <ToastContainer />
      <section className="py-5 item-bg position-relative">
        <div id="particles-js"></div>
        <div className="container py-5 mt-5 z-index-20">
          <h1 className="text-center">Item Details</h1>

          <nav aria-label="breadcrumb">
            <ol className="breadcrumb justify-content-center">
              <li className="breadcrumb-item">
                <a
                  className="text-decoration-none d-flex align-items-center"
                  href="#"
                >
                  {" "}
                  <i className="las la-home la-sm me-1"></i>Home
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Item Details
              </li>
            </ol>
          </nav>
        </div>
      </section>

      <section className="py-5">
        <div className="container py-5">
          <div className="row mb-4 gy-4">
            <div className="col-lg-6">
              <div className="card shadow">
                <div className="card-body m-auto">
                  {nftData.image && (
                    <img
                      class="img-fluid rounded w-100"
                      src={nftData.image}
                      alt=""
                    />
                  )}
                  {nftData.media_type && nftData.media_type === "video" && (
                    <video
                      width="320"
                      height="240"
                      controlsList="nodownload"
                      controls
                    >
                      <source
                        src={nftData.animation_url}
                        type={nftData.animation_type}
                      />
                      Your browser does not support the video element.
                    </video>
                  )}

                  {nftData.media_type && nftData.media_type === "audio" && (
                    <audio controlsList="nodownload" controls>
                      <source
                        src={nftData.animation_url}
                        type={nftData.animation_type}
                      />
                      Your browser does not support the audio element.
                    </audio>
                  )}
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <p className="mb-2 text-muted">
                {type === 2 ? "Start price" : "Price"}
                <span className="fw-bold ms-3 badge bg-primary">
                  {nftData.startPrice} {process.env.REACT_APP_TOKEN_SYMBOL}
                </span>
              </p>
              <h1 style={{ color: "#fff" }}>{nftData.name}</h1>
              <p className="text-muted mb-4">{nftData.description}</p>

              <div className="row mb-4">
                <div className="col-xl-8">
                  <div className="card shadow-sm rounded">
                    <div className="card-body p-4">
                      <ul className="list-unstyled text-sm text-muted mb-0">
                        <li className="d-flex align-items-center justify-content-between mb-2 pb-1">
                          <p className="mb-0 d-flex align-items-center">
                            <i className="text-primary las la-user-circle"></i>
                            <span className="ms-2">Creator:</span>
                          </p>
                          <p className="mb-0 text-gray-muted">
                            {truncateEthAddress(nftData.owner)}
                          </p>
                        </li>
                        <li className="d-flex align-items-center justify-content-between mb-2 pb-1">
                          <p className="mb-0 d-flex align-items-center">
                            <i className="text-primary las la-clock"></i>
                            <span className="ms-2">Created:</span>
                          </p>
                          <p className="mb-0 text-gray-muted">
                            {nftData.created}
                          </p>
                        </li>
                        {/* <li className="d-flex align-items-center justify-content-between mb-2 pb-1">
                          <p className="mb-0 d-flex align-items-center">
                            <i className="text-primary las la-crop-alt"></i>
                            <span className="ms-2">Item Size</span>
                          </p>
                          <p className="mb-0 text-gray-muted">2200 x 2500</p>
                        </li>
                        <li className="d-flex align-items-center justify-content-between mb-2 pb-1">
                          <p className="mb-0 d-flex align-items-center">
                            <i className="text-primary las la-photo-video"></i>
                            <span className="ms-2">Formats</span>
                          </p>
                          <p className="mb-0 text-gray-muted">MP4, JPEG</p>
                        </li>
                        <li className="d-flex align-items-center justify-content-between mb-0">
                          <p className="mb-0 d-flex align-items-center">
                            <i className="text-primary las la-save"></i>
                            <span className="ms-2">Downloadable file</span>
                          </p>
                          <p className="mb-0 text-gray-muted">Yes</p>
                        </li> */}
                      </ul>

                      {active && (
                        <button
                          class="btn btn-primary btn-sm w-100 mt-4"
                          href=""
                          className={`btn btn-primary btn-sm w-100 mt-4" ${
                            type === 1 && !nftData.isOwner && !bidded
                              ? ""
                              : "d-none"
                          }`}
                          onClick={() => buyNft(nftData)}
                        >
                          Buy Item
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* <h2 className="h5 mb-4">Auction Ending In</h2>
              <div className="row">
                <div className="col-xl-8">
                  <div className="card rounded">
                    <div className="card-body p-1">
                      <div
                        className="p-2 counter d-flex align-items-center justify-content-center text-center w-100 counter-item mb-2"
                        data-date="7-12-2021"
                        data-time="12:00"
                      >
                        <div className="day counter-item">
                          <strong className="num h5 mb-0"></strong>
                          <span className="text-sm d-block mt-2 word"></span>
                        </div>
                        <div className="hour counter-item">
                          <strong className="num h5 mb-0"></strong>
                          <span className="text-sm d-block mt-2 word"></span>
                        </div>
                        <div className="min counter-item">
                          <strong className="num h5 mb-0"></strong>
                          <span className="text-sm d-block mt-2 word"></span>
                        </div>
                        <div className="sec counter-item">
                          <strong className="num h5 mb-0"></strong>
                          <span className="text-sm d-block mt-2 word"></span>
                        </div>
                      </div>
                      <a className="btn btn-primary w-100" href="#!">
                        {" "}
                        <i className="las la-calendar-check me-2"></i>Add to
                        calendar{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          <div className="row gy-4 ">
            <div className="col-12">
              <ul className="list-inline d-flex align-items-center">
                <li className="list-inline-item me-2 d-none">
                  <a href="#!">
                    {" "}
                    <img
                      className="img-fluid rounded-circle p-1 bg-dark"
                      src={image2}
                      alt="..."
                      width="50"
                    />
                  </a>
                </li>
                <li className="list-inline-item me-3 d-none">
                  <a className="text-reset" href="#!">
                    {" "}
                    <h6 className="mb-0">Monica Lucas</h6>
                  </a>
                </li>
                <li className="list-inline-item d-flex align-items-center d-none">
                  <div className="icon bg-primary me-2">
                    <i className="las la-check-double text-white fw-bold"></i>
                  </div>
                  <span className="text-muted text-sm">Verified user</span>
                </li>
              </ul>
            </div>

            <div className="col-lg-6">
              <div
                className={`bg-dark rounded shadow-sm p-1 ${
                  type === 2 && !nftData.isOwner ? "" : "d-none"
                } `}
              >
                <ul
                  className="nav nav-pills flex-column flex-sm-row mb-0"
                  id="item-tab"
                  role="tablist"
                >
                  <li className="nav-item flex-sm-fill" role="presentation">
                    <button
                      className="nav-link w-100 active"
                      id="item-bids-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#item-bids"
                      type="button"
                      role="tab"
                      aria-controls="item-bids"
                      aria-selected="true"
                    >
                      Bids
                    </button>
                  </li>

                  {/* <li className="nav-item flex-sm-fill" role="presentation">
                    <button
                      className="nav-link w-100"
                      id="item-description-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#item-description"
                      type="button"
                      role="tab"
                      aria-controls="item-description"
                      aria-selected="false"
                    >
                      Description
                    </button>
                  </li>
                  <li className="nav-item flex-sm-fill" role="presentation">
                    <button
                      className="nav-link w-100"
                      id="item-history-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#item-history"
                      type="button"
                      role="tab"
                      aria-controls="item-history"
                      aria-selected="false"
                    >
                      History
                    </button>
                  </li> */}
                </ul>
              </div>

              <div
                id="item-tabContent"
                className={`tab-content py-3 ${type === 2 ? "" : "d-none"} `}
              >
                <div
                  className="tab-pane fade show active"
                  id="item-bids"
                  role="tabpanel"
                  aria-labelledby="item-bids-tab"
                >
                  {bids.length === 0 ? "No Bids Yet" : ""}
                  <ul className="list-unstyled">
                    {bids.map((data, i) => {
                      return (
                        <li className="d-flex align-items-center rounded p-3 bd-3 bg-dark mb-2 card-hover-minimal">
                          {/* <img
                            className="img-fluid rounded-circle"
                            src={image2}
                            alt="..."
                            width="50"
                          /> */}
                          <div className="ms-2">
                            <p className="text-sm mb-1 text-muted">
                              Bid accepted{" "}
                              <span className="text-primary">
                                {data.bid} {process.env.REACT_APP_TOKEN_SYMBOL}
                              </span>
                            </p>
                            <p className="text-sm mb-0 text-muted">
                              by{" "}
                              <span className="text-primary">
                                {data.bidder}{" "}
                              </span>
                              {/* at 6/15/2021, 3:20 AM */}
                            </p>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div
                  className="tab-pane fade"
                  id="item-description"
                  role="tabpanel"
                  aria-labelledby="item-description-tab"
                >
                  <h6>Description:</h6>
                  <p className="text-muted text-sm">
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                    Dolorem illum, sapiente dolor voluptatum deleniti sunt in
                    natus aliquam libero. Totam, officiis porro? Quos dolor
                    molestiae enim! Ipsa quaerat temporibus totam!
                  </p>
                  <p className="text-muted text-sm">
                    Fugit praesentium inventore earum excepturi minima laborum
                    laudantium dolores vitae placeat!Nesciunt qui amet fuga
                    beatae sequi ipsum eaque minus itaque id deserunt eum maxime
                    perspiciatis reiciendis accusantium, aliquam illum deleniti.
                    Minus ducimus eveniet repellendus, velit dolor est odit
                    possimus! Modi.
                  </p>
                </div>

                <div
                  className="tab-pane fade"
                  id="item-history"
                  role="tabpanel"
                  aria-labelledby="item-history-tab"
                >
                  <li className="d-flex align-items-center rounded p-3 bd-3 bg-dark mb-2 card-hover-minimal">
                    <img
                      className="img-fluid rounded-circle"
                      src={image2}
                      alt="..."
                      width="50"
                    />
                    <div className="ms-2">
                      <p className="text-sm mb-1 text-muted">
                        Bid <span className="text-primary">0.005 BNB</span>
                      </p>
                      <p className="text-sm mb-0 text-muted">
                        by <span className="text-primary">Monica Lucas </span>at
                        6/15/2021, 3:20 AM
                      </p>
                    </div>
                  </li>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="row">
                <div className="col-xl-8">
                  <h2 className="h5 mb0">Place a bid</h2>
                  <p className="text-muted text-sm mb-0 d-none">
                    Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                    Debitis id nobis dolor consequuntur.
                  </p>

                  <form
                    className={`mb-2 ${
                      type === 2 && !nftData.isOwner && !nftData.auctionEnded
                        ? ""
                        : "d-none"
                    } `}
                    onSubmit={handleBid}
                  >
                    <div className="input-group p-1 shadow-sm rounded bg-dark founded">
                      <input
                        className="form-control border-0 shadow-0 bg-dark"
                        type="text"
                        name="amount"
                        autocomplete="off"
                        placeholder="Enter your bid..."
                        required
                      />
                      <button
                        className="btn btn-primary btn-sm rounded-sm"
                        type="submit"
                      >
                        <i className="fas fa-paper-plan"></i>Place bid
                      </button>
                    </div>
                  </form>

                  {type === 2 ? message : ""}
                  <h2 className="h5 mb-3 d-none">Share this item</h2>
                  <ul className="list-inline d-none">
                    <li className="list-inline-item">
                      <a className="social-link" href="#">
                        {" "}
                        <i className="lab la-facebook-f"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a className="social-link" href="#">
                        {" "}
                        <i className="lab la-pinterest"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a className="social-link" href="#">
                        {" "}
                        <i className="lab la-twitter"></i>
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a className="social-link" href="#">
                        {" "}
                        <i className="las la-link"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Item;
